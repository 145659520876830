<!--
 * @Description: 编辑器
 * @Author: zhang zhen
 * @Date: 2023-02-19 14:56:08
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-02 10:08:45
 * @FilePath: /page-sass/src/components/easyComponents/PEditor.vue
-->
<template>
    <div class="tinymce-editor">
      <editor v-if="!reloading" v-model="myValue" :init="init" :disabled="disabled" @onClick="onClick"> </editor>
    </div>
  </template>
  
  <script>
  import tinymce from 'tinymce/tinymce'
  import Editor from '@tinymce/tinymce-vue'
  import 'tinymce/themes/silver/theme'
  import 'tinymce/plugins/image'
  import 'tinymce/plugins/media'
  import 'tinymce/plugins/table'
  import 'tinymce/plugins/lists'
  import 'tinymce/plugins/contextmenu'
  import 'tinymce/plugins/wordcount'
  import 'tinymce/plugins/colorpicker'
  import 'tinymce/plugins/textcolor'
  import 'tinymce/plugins/fullscreen'
  import 'tinymce/icons/default/icons.min.js'
  import { getVmParentByName } from '@/util/utils'
  
  export default {
    components: {
      editor: Editor,
    },
    props: {
      value: {
        type: String,
        required: false,
      },
      triggerChange: {
        type: Boolean,
        default: false,
        required: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      plugins: {
        type: [String, Array],
        default: 'lists image media table textcolor wordcount contextmenu fullscreen',
      },
      toolbar: {
        type: [String, Array],
        default:
          'undo redo |  formatselect | forecolor backcolor bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists table | removeformat',
      },
    },
    data() {
      return {
        //初始化配置
        init: {
          language_url: '/tinymce/langs/zh_CN.js',
          language: 'zh_CN',
          skin_url: '/tinymce/skins/lightgray',
          height: 520,
          plugins: this.plugins,
          toolbar: this.toolbar,
          branding: false,
          menubar: false,
          toolbar_drawer: false,
          images_upload_handler: (blobInfo, success) => {
            const img = 'data:image/jpeg;base64,' + blobInfo.base64()
            success(img)
          },
        },
        myValue: this.value,
        reloading: false,
      }
    },
    mounted() {
      this.initATabsChangeAutoReload()
    },
    methods: {
      reload() {
        this.reloading = true
        this.$nextTick(() => (this.reloading = false))
      },
      /**
       * 自动判断父级是否是 <a-tabs/> 组件，然后添加事件监听，自动触发reload()
       *
       * 由于 tabs 组件切换会导致 tinymce 无法输入，
       * 只有重新加载才能使用（无论是vue版的还是jQuery版tinymce都有这个通病）
       */
      initATabsChangeAutoReload() {
      console.log("initATabsChangeAutoReload -> initATabsChangeAutoReload",  this.reloading)
        
        // 获取父级
        let tabs = getVmParentByName(this, 'ATabs')
        let tabPane = getVmParentByName(this, 'ATabPane')
        if (tabs && tabPane) {
          console.log("initATabsChangeAutoReload -> tabs", tabs)
          // 用户自定义的 key
          let currentKey = tabPane.$vnode.key
            this.reload()
          // 添加事件监听
          tabs.$on('change', (key) => {
            // 切换到自己时执行reload
            if (currentKey === key) {
              console.log("initATabsChangeAutoReload -> currentKey", currentKey)
              this.reload()
            }
          })
        } else {
          this.reload()
        }
      },
      onClick(e) {
        this.$emit('onClick', e, tinymce)
      },
      //可以添加一些自己的自定义事件，如清空内容
      clear() {
        this.myValue = ''
      },
    },
    watch: {
      value(newValue) {
        this.myValue = newValue == null ? '' : newValue
      },
      myValue(newValue) {
       
        if (this.triggerChange) {
         
          this.$emit('change', newValue)
        } else {
         
          this.$emit('input', newValue)
        }
      },
    },
  }
  </script>
  <style scoped>
  </style>